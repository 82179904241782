import 'slick-carousel';

/**
 * Lazy Loading based on lozad.js
 *
 * @type {{init: lazy.init, isInitialized: boolean, bootstrap: (function(): {observer, triggerLoad, observe})}}
 */
export const packages = {
  isInitialized: false,

  init: function () {
    if (!this.isInitialized) {
      $('.packages').each((i, el) => {
        this.initEmptySlide(el);
        this.bootstrap(el);
        this.triggerEvents(el);
      });
    }
  },

  triggerEvents: function (el) {
    $(window).on('resize', function () {
      packages.initEmptySlide(el);
    });
  },

  initEmptySlide: function (el) {
    const margin = $('.container.alignwide')[0].getClientRects()[0].x;
    $(el)
      .find('.packages__slide')
      .first()
      .css('margin-left', Math.floor(margin) - 15);
  },

  bootstrap: function (el) {
    const slider = $(el).find('.packages__slider');
    if (slider.length) {
      $(slider).slick({
        dots: false,
        focusOnSelect: false,
        infinite: false,
        variableWidth: true,
        draggable: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $(el).find('.packages__arrow-prev'),
        nextArrow: $(el).find('.packages__arrow-next'),
        centerPadding: '40px',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
            },
          },
        ],
      });
    }
  },
};
