import 'slick-carousel';
import { modal } from './modal';

/**
 * Lazy Loading based on lozad.js
 *
 * @type {{init: lazy.init, isInitialized: boolean, bootstrap: (function(): {observer, triggerLoad, observe})}}
 */
export const testimonials = {
  isInitialized: false,

  init: function () {
    // Init lozad.js
    if (!this.isInitialized) {
      $('.testimonials').each((i, el) => {
        this.initEmptySlide(el);
        this.bootstrap(el);
        this.triggerEvents(el);
      });
    }
  },

  triggerEvents: function (el) {
    $(window).on('resize', function () {
      testimonials.initEmptySlide(el);
    });

    $(el)
      .not('.slick-initialized')
      .find('.testimonials__more-btn')
      .on('click', function (e) {
        e.preventDefault();

        let data = this.closest('.testimonials__card');
        let copy = data.cloneNode(true);

        let createdModal = modal.create(copy);

        createdModal.classList.add('alignfull');
        createdModal
          .getElementsByClassName('testimonials__card')[0]
          .classList.remove('mt-16', 'mb-4', 'lg:mb-12', 'max-h-sm');
        createdModal.getElementsByClassName('testimonials__shade')[0].remove();
        createdModal
          .getElementsByClassName('testimonials__more-btn')[0]
          .remove();
      });
  },

  initEmptySlide: function (el) {
    const margin = $('.container.alignwide')[0].getClientRects()[0].x;
    $(el)
      .not('.slick-initialized')
      .find('.testimonials__slide')
      .first()
      .css('margin-left', Math.floor(margin) - 15);
  },

  bootstrap: function (el) {
    $(el)
      .not('.slick-initialized')
      .find('.testimonials__slider')
      .slick({
        accessibility: false,
        dots: false,
        focusOnSelect: false,
        infinite: false,
        variableWidth: true,
        draggable: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $(el).find('.testimonials__arrow-prev'),
        nextArrow: $(el).find('.testimonials__arrow-next'),
        centerPadding: '40px',
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              dots: false,
              infinite: true,
            },
          },
        ],
      });
  },
};
