import { settings } from './config';
// import detectIt from 'detect-it';

export const helpers = {
  log: (input, ...other) => {
    if (settings.wp.env == 'production') return;

    if (input) {
      console.log(input, other);
    } else {
      console.log('[err] helpers -> log() -> input missing');
    }
  },

  isMobile: () => {
    // let viewportWidth = window.innerWidth;
    // if ( viewportWidth >= config.breakpoints.md ) return false;
    // if ( detectIt.deviceType === 'touchOnly' ) return true;
    // if ( detectIt.deviceType === 'hybrid' ) return true;
    // return false;
  },
};
