import { helpers } from './helpers';
import { modal } from './modal';

export const wooSub = {
  isInitialized: false,

  /**
   * Init modules
   */
  init: function () {
    if (!wooSub.isInitialized) {
      wooSub.isInitialized = true;

      this.triggerEvents();
    }
  },

  triggerEvents: function () {
    $('.sf-sub-make-pause').on('click', function () {
      let sub = $(this).closest('.sf-subscription');
      let id = $(sub).data('id');

      helpers.log(id);

      let data = $('#sf-sub-modal-add-break')[0];

      helpers.log(data);

      let copy = data.cloneNode(true);

      let modalInstance = modal.create(copy);

      modalInstance.classList.add('alignfull');

      setTimeout(() => {
        let datepickerNode = $(modalInstance).find(
          '#sf-sub-modal-add-break-datepicker'
        );

        helpers.log(datepickerNode);

        let datepickerInstance = $(datepickerNode).datepicker({
          dateFormat: 'yy-mm-dd',
          dayNamesMin: ['P', 'E', 'T', 'K', 'N', 'R', 'L'],
          minDate: 'today+3',
          firstDay: 1, // start week from monday
          autoSize: true,
          numberOfMonths: 1,
          showOtherMonths: false,
          showButtonPanel: true,
        });
      }, 750);
    });
  },
};
