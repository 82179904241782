import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
export default class SmartDatepicker {
  constructor(datepicker, options = {}) {
    this.target = datepicker;
    this.instance = null;
    this.day = null;
    this.hours = null;
    this.excludedDates = [];
    this.restrictedStartDates = [];
    this.activeDates = [];
    this.activePastDates = [];
    this.changeDates = [];
    this.changeDatesQueued = [];
    this.skipDates = [];
    this.skipQueueDates = [];
    this.debounceTimeout = null;
    this.defaultNumberOfMonths = 3;
    this.options = {
      dateFormat: 'yy-mm-dd',
      minDate: 'today+3',
      maxDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 6,
        +0
      ),
      firstDay: 1, // start week from monday
      autoSize: true,
      numberOfMonths: 2,
      showOtherMonths: false,
      showButtonPanel: true,
      ...options,
    };

    this.init();
    this.bootstrap();
    this.triggerEvents();
  }

  init() {
    dayjs.extend(customParseFormat);
  }

  bootstrap() {
    this.day = new Date().getDay();
    this.hours = new Date().getHours();

    if (window.smart.datepicker.sub_active_dates) {
      this.activeDates = window.smart.datepicker.sub_active_dates;
    }

    if (window.smart.datepicker.excluded_dates) {
      this.excludedDates = window.smart.datepicker.excluded_dates;
    }

    if (window.smart.datepicker.restricted_start_dates) {
      this.restrictedStartDates =
        window.smart.datepicker.restricted_start_dates;
    }

    if (window.smart.datepicker.sub_skip_dates) {
      this.skipDates = window.smart.datepicker.sub_skip_dates;
    }

    if (window.smart.datepicker.sub_queued_skip_dates) {
      this.skipQueueDates = window.smart.datepicker.sub_queued_skip_dates;
    }

    if (window.smart.datepicker.sub_queued_change_dates) {
      this.changeDatesQueued = window.smart.datepicker.sub_queued_change_dates;
    }

    if (window.smart.datepicker.day_names_min) {
      this.options = {
        ...this.options,
        dayNamesMin: window.smart.datepicker.day_names_min,
      };
    }

    if (window.smart.datepicker.month_names) {
      this.options = {
        ...this.options,
        monthNames: window.smart.datepicker.month_names,
      };
    }

    if (window.smart.datepicker.next_text) {
      this.options = {
        ...this.options,
        nextText: window.smart.datepicker.next_text,
      };
    }

    if (window.smart.datepicker.prev_text) {
      this.options = {
        ...this.options,
        prevText: window.smart.datepicker.prev_text,
      };
    }

    // console.log(this);

    this.instance = $(this.target).datepicker(this.options);

    this.instance.datepicker('option', {
      beforeShowDay: $.datepicker.noWeekends,
    });

    this.instance.datepicker('option', {
      beforeShowDay: (date) => {
        date = $.datepicker.formatDate('yy-mm-dd', date);

        if (this.excludedDates.indexOf(date) != -1) {
          return [false, ''];
        } else if (this.skipDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [true, 'sf-pause-past', 'SmartFOOD'];
          } else {
            return [true, 'sf-pause'];
          }
        } else if (this.activeDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [true, 'sf-active-past', 'SmartFOOD'];
          } else {
            if (this.restrictedStartDates.indexOf(date) != -1) {
              return [false, 'sf-active--restricted'];
            }
            return [true, 'sf-active', 'SmartFOOD'];
          }
        } else if (this.restrictedStartDates.indexOf(date) != -1) {
          return [false, ''];
        } else {
          return [true, ''];
        }
      },
    });
  }

  triggerEvents() {
    $(window)
      .on('resize', () => {
        clearTimeout(this.debounceTimeout);
        if ($(window).width() < 768) {
          this.debounceTimeout = setTimeout(() => {
            this.debounce(1);
          }, 250);
        } else if ($(window).width() < 1600) {
          this.debounceTimeout = setTimeout(() => {
            this.debounce(2);
          }, 250);
        } else {
          this.debounceTimeout = setTimeout(() => {
            this.debounce(this.defaultNumberOfMonths);
          }, 250);
        }
      })
      .trigger('resize');
  }

  addChangeDate(date) {
    if ($.inArray(date, this.changeDates) < 0) this.changeDates.push(date);
  }

  removeChangeDate(index) {
    this.changeDates.splice(index, 1);
  }

  addSkipDate(date) {
    if ($.inArray(date, this.skipDates) < 0) this.skipDates.push(date);
  }

  removeSkipDate(index) {
    this.skipDates.splice(index, 1);
  }

  padNumber(number) {
    var ret = new String(number);
    if (ret.length == 1) ret = '0' + ret;
    return ret;
  }

  addOrRemoveChangeDate(date) {
    var index = $.inArray(date, this.changeDates);
    if (index >= 0) this.removeChangeDate(index);
    else this.addChangeDate(date);
  }

  // Adds a date if we don't have it yet, else remove it
  addOrRemoveSkipDate(date) {
    var index = $.inArray(date, this.skipDates);
    if (index >= 0) this.removeSkipDate(index);
    else this.addSkipDate(date);
  }

  debounce(number) {
    this.instance.datepicker('option', 'numberOfMonths', number);
  }
}
