import {
  wooSub
} from "../modules/wooSub";

/**
 * Common
 */
export default () => {
  wooSub.init();
};
