/**
 * Init Menu modules
 *
 * @type {{init: menu.init, isInitialized: boolean}}
 */
export const menu = {
  isInitialized: false,

  /**
   * Init modules
   */
  init: function () {
    if (!menu.isInitialized) {
      this.bootstrap();
      this.isInitialized = true;
    }
  },

  bootstrap: function () {
    const header = document.getElementById('site-header');
    const openBtn = document.getElementById('site-header-open');
    const closeBtn = document.getElementById('site-header-close');

    openBtn.addEventListener('click', () => {
      header.classList.add('site-header--active');
    });

    closeBtn.addEventListener('click', () => {
      header.classList.remove('site-header--active');
    });
  },
};
