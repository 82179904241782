import { helpers } from './helpers';

// Fancybox autoloads
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export const modal = {

  isInitialized:  false,

  isOpen: false,

  current: null,

  timeout: null,

  closeBtn: null,

  triggerEvents: function () {

    helpers.log('(module) modal -> triggerEvents()');

    modal.closeBtn = document.getElementsByClassName('modal__close-btn')[0];

    modal.closeBtn.addEventListener('click', () => {
      modal.close();
    });
  },

  /**
   * Create new modal
   * @param {boolean} full set true for full screen modal
   * @returns modal inner element for targeting content
   */
  create: function ( data ) {

    helpers.log('(module) modal -> create()');

    // Clear filter timer
    clearTimeout( this.timeout );

    // Restrict body scroll
    document.body.classList.add('overflow-y-hidden');

    var modal = document.createElement('div');
    modal.setAttribute('id', 'modal');
    modal.classList.add('modal', 'container');

    var close_btn = document.createElement('div');
    close_btn.classList.add('modal__close-btn');
    close_btn.innerHTML = '<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>';

    let wrapper = document.createElement('div');
    wrapper.classList.add('modal__wrapper');

    var inner = document.createElement('div');
    inner.classList.add('modal__inner');

    inner.appendChild( close_btn );

    inner.appendChild( data );

    wrapper.appendChild( inner );

    modal.appendChild( wrapper );

    document.body.appendChild( modal );

    this.current = modal;

    this.current.classList.add('modal--open');

    this.isOpen = true;

    setTimeout(() => {
      this.animateOpen();
    }, 200);

    return inner;
  },

  animateOpen: function () {

    this.current.classList.add('modal--visible');

    // Set timeout for filters to apply
    this.timeout = setTimeout(() => {
      this.current.classList.add('modal--active');
      this.triggerEvents();
    }, 300);
  },

  close: function () {

    helpers.log('(module) modal -> close()');

    if (modal.isOpen) {
      modal.animateClose();
    }

    modal.isOpen = false;
  },

  animateClose: function () {

    helpers.log('(module) modal -> animateClose()');

    this.current.classList.remove('modal--visible');

    setTimeout(() => {
      modal.remove();
    }, 200);
  },

  remove: function () {

    helpers.log('(module) modal -> remove()');

    if(modal.current) {
      modal.current.remove();
      modal.current = null;

      // Allow body scroll
      document.body.classList.remove('overflow-y-hidden');
    }
  },
}
