import SmartDatepicker from '../../classes/SmartDatepicker';
import dayjs from 'dayjs';
import RegioAddressSearchBox from './RegioAddressSearchBox';
import { helpers } from '../helpers';

export const sfPackage = {
  isInitialized: false,
  regioSearchBox: null,
  periodDatepicker: null,
  minOrderDate: null,
  state: {
    sf_attribute_pa_periood: null,
    sf_variation_id: null,
    days: null,
    campaigns: {},
    sf_sub_reference: null,
    sf_sub_start_date: null,
    sf_sub_end_date: null,
    sf_sub_contact_first_name: null,
    sf_sub_contact_last_name: null,
    sf_sub_contact_phone: null,
    sf_sub_contact_email: null,
    sf_sub_delivery_shipping_zone: null,
    sf_sub_delivery_postcode: null,
    sf_sub_delivery_address: null,
    sf_sub_delivery_address_a2: null,
    sf_sub_delivery_address_a5: null,
    sf_sub_delivery_building_type: null,
    sf_sub_delivery_time: null,
    sf_sub_delivery_time_val: null,
  },
  validate: {
    variation_id: false,
    sf_attribute_pa_periood: false,
  },
  validateTimeout: null,
  product_id: null,
  variation_id: null,
  pa_periood: null,
  can_order: null,
  subConfig: null,

  init: function () {
    if (!this.isInitialized) {
      this.bootstrap();
      this.triggerEvents();
      this.preFillData();
      sfPackage.validateOrder();
    }
  },

  bootstrap: function () {
    sfPackage.product_id = $('.variations_form').data('product_id');
    // Set min pause change date
    if (dayjs().isBefore(dayjs().startOf('day').add(16, 'h'))) {
      sfPackage.minOrderDate = dayjs()
        .startOf('day')
        .add(2, 'd')
        .format('YYYY-MM-DD');
    } else {
      sfPackage.minOrderDate = dayjs()
        .startOf('day')
        .add(3, 'd')
        .format('YYYY-MM-DD');
    }

    const sfSubReference = $('[name="sf_sub_reference"]');
    if (sfSubReference.length) {
      Object.assign(sfPackage.validate, {
        sf_sub_reference: false,
      });
    } else {
      Object.assign(sfPackage.validate, {
        sf_sub_start_date: false,
        sf_sub_contact_first_name: false,
        sf_sub_contact_last_name: false,
        sf_sub_contact_phone: false,
        sf_sub_contact_email: false,
        sf_sub_delivery_postcode: false,
        sf_sub_delivery_shipping_zone: false,
        sf_sub_delivery_address: false,
        sf_sub_delivery_address_a2: false,
        sf_sub_delivery_address_a5: false,
        sf_sub_delivery_building_type: false,
        sf_sub_delivery_time: false,
        sf_sub_delivery_info_territory_limited: false,
      });
    }

    const periodStart = $('#period-start');
    if (periodStart.length) {
      sfPackage.periodDatepicker = new SmartDatepicker(periodStart, {
        minDate: sfPackage.minOrderDate,
      });
    }

    const searchBox = $('[name="sf_sub_start_date"]');
    if (searchBox.length) {
      sfPackage.regioSearchBox = new RegioAddressSearchBox();
    }

    if (window.smart.sub_config) {
      sfPackage.subConfig = window.smart.sub_config;
    }
  },

  getCombinedTotalDays: function () {
    const days = sfPackage.state.days;
    const campaigns = sfPackage.state.campaigns;
    let totalDays = days;

    if (jQuery.isEmptyObject(campaigns)) return days;

    Object.values(campaigns).forEach((value) => {
      totalDays = totalDays + value.extra_days;
    });

    return totalDays;
  },

  clearAddressFields: function () {
    [
      'sf_sub_delivery_postcode',
      'sf_sub_delivery_address',
      'sf_sub_delivery_address_a1',
      'sf_sub_delivery_address_a2',
      'sf_sub_delivery_address_a3',
      'sf_sub_delivery_address_a4',
      'sf_sub_delivery_address_a5',
      'sf_sub_delivery_address_a6',
      'sf_sub_delivery_address_a7',
      'sf_sub_delivery_address_a8',
      'sf_sub_delivery_shipping_zone',
    ].map((item) => $(`[name="${item}"]`).val('').trigger('change'));
  },

  parseRegioResponse: function (event) {
    helpers.log(event);

    if (typeof event.detail.postcode !== 'undefined') {
      $('[name="sf_sub_delivery_postcode"]')
        .val(event.detail.postcode)
        .trigger('change');
    }

    if (typeof event.detail.address !== 'undefined') {
      $('[name="sf_sub_delivery_address"]')
        .val(event.detail.address)
        .trigger('change');
    }

    if (typeof event.detail.components !== 'undefined') {
      event.detail.components.map((item) => {
        switch (item.type) {
          case 'A1':
            $('[name="sf_sub_delivery_address_a1"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A2':
            $('[name="sf_sub_delivery_address_a2"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A3':
            $('[name="sf_sub_delivery_address_a3"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A4':
            $('[name="sf_sub_delivery_address_a4"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A5':
            $('[name="sf_sub_delivery_address_a5"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A6':
            $('[name="sf_sub_delivery_address_a6"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A7':
            $('[name="sf_sub_delivery_address_a7"]')
              .val(item.name)
              .trigger('change');
            break;

          case 'A8':
            $('[name="sf_sub_delivery_address_a8"]')
              .val(item.name)
              .trigger('change');
            break;

          default:
            break;
        }
      });
    }
  },

  triggerEvents: function () {
    if (sfPackage.periodDatepicker) {
      sfPackage.periodDatepicker.instance.datepicker('option', {
        onSelect: function (dateText) {
          $('#sf-sub-start-date').val(dateText);
          sfPackage.updateState('sf_sub_start_date', dateText, true);
          sfPackage.updateEndDate(
            sfPackage.state.sf_sub_start_date,
            sfPackage.getCombinedTotalDays()
          );
        },
      });
    }

    if (sfPackage.regioSearchBox) {
      sfPackage.regioSearchBox.input.addEventListener(
        'addressresultitemchange',
        (event) => {
          if (event.detail === null) {
            sfPackage.clearAddressFields();
            return;
          }

          sfPackage.clearAddressFields();

          sfPackage.regioSearchBox.input.value = event.detail.address;

          setTimeout(() => sfPackage.parseRegioResponse(event), 250);
        }
      );
    }

    $('[name="sf_sub_reference"]').on('change', function () {
      const field = $('[name="sf_sub_reference"]');

      if (field.val()) {
        sfPackage.updateState('sf_sub_reference', field.val(), true);
      } else {
        sfPackage.updateState('sf_sub_reference', null, false);
      }

      sfPackage.validateOrder();
    });

    $('[name="sf_attribute_pa_periood"]').on('change', function () {
      let $selected = $('[name="sf_attribute_pa_periood"]:checked');

      sfPackage.variation_id = $selected.data('id');

      sfPackage.updateState('days', $selected.data('days'), true);
      sfPackage.updateState('variation_id', $selected.data('id'), true);
      sfPackage.pa_periood = $selected.val();
      sfPackage.updateState(
        'sf_attribute_pa_periood',
        $selected.data('value'),
        true
      );

      sfPackage.checkExistingCampaigns();

      if (sfPackage.state.sf_sub_start_date && sfPackage.state.days) {
        sfPackage.updateEndDate(
          sfPackage.state.sf_sub_start_date,
          sfPackage.getCombinedTotalDays()
        );
      }

      const $select = $('select[name="attribute_pa_periood"]');
      if ($select.val() !== $selected.val()) {
        $select.val($selected.val());
        $select.trigger('change');
      }
    });

    $('#sf-product-campaigns-check').on('click', function () {
      const campaignCode = $('[name="sf_campaign_code"]').val();
      if (campaignCode) {
        $.ajax({
          type: 'POST',
          url: window.smart.ajax.url,
          dataType: 'json',
          data: {
            action: 'sf_product_check_campaign_code',
            args: {
              product_id: sfPackage.product_id,
              variation_id: sfPackage.variation_id,
              code: campaignCode,
            },
          },
          beforeSend: function () {
            $('#campaign-code-error').remove();
            $('.sf-summary').addClass('loader');
            $('.period-selector').addClass('loader');
          },
          success: function (response) {
            if (response.matched) {
              $('[name="sf_campaign_code"]').val('');
              sfPackage.parseCampaigns(response.matched);
              return;
            }

            if (response.message) {
              $('.campaign-code').append(`<div id="campaign-code-error" class="font-semibold text-right">${response.message}</div>`);

              setTimeout(() => {
                $('#campaign-code-error').remove();
              }, 10000);
            }

            helpers.log('No campaigns found.');
          },
          error: function (err) {
            helpers.log('ajax error', err);
          },
          complete: function () {
            $('.sf-summary').removeClass('loader');
            $('.period-selector').removeClass('loader');
          },
        });
      }
    });

    $('#sf-sub-extras [type="checkbox"]').on('change', function () {
      let checked = $('#sf-sub-extras [type="checkbox"]:checked');

      if (checked.length) {
        $('#summary-sf_sub_extras-content').empty();

        $(checked).each((index, el) => {
          let key = $(el).attr('name');
          let val = $(el).data('value');

          let node = document.createElement('span');
          node.dataset.name = key;
          $('#summary-sf_sub_extras-content').append(node);
          $(node).html(val);
        });

        $('#summary-sf_sub_extras-state').addClass(
          'sf-summary__item-state--ok'
        );
        $('#summary-sf_sub_extras-content').removeClass('hidden');
      } else {
        $('#summary-sf_sub_extras-content').html('-');
        $('#summary-sf_sub_extras-state').removeClass(
          'sf-summary__item-state--ok'
        );
        $('#summary-sf_sub_extras-content').addClass('hidden');
      }

      sfPackage.validateOrder();
    });

    Object.values([
      'sf_sub_contact_first_name',
      'sf_sub_contact_last_name',
      'sf_sub_contact_phone',
      'sf_sub_contact_email',
    ]).forEach((key) => {
      $(`[name="${key}"]`).on('change', function () {
        const contactField = $(`[name="${key}"]`);

        if (contactField.val()) {
          sfPackage.updateState(key, contactField.val(), true);
        } else {
          sfPackage.updateState(key, null, false);
        }

        sfPackage.validateOrder();
      });
    });

    $('[name="sf_sub_delivery_postcode"]').on('change', function () {
      const postcode = $('[name="sf_sub_delivery_postcode"]');
      if (postcode.val()) {
        $.ajax({
          type: 'POST',
          url: window.smart.ajax.url,
          dataType: 'json',
          data: {
            action: 'sf_product_validate_postcode',
            args: {
              product_id: sfPackage.product_id,
              variation_id: sfPackage.variation_id,
              postcode: postcode.val(),
            },
          },
          beforeSend: function () {
            $('.sf-summary').addClass('loader');
          },
          success: function (response) {
            // console.log('ajax success', response);

            $('[name="sf_sub_delivery_shipping_zone"]')
              .val(response.shipping_zone.id)
              .trigger('change');

            sfPackage.updateState(
              'sf_sub_delivery_postcode',
              postcode.val(),
              response.can_order
            );
          },
          error: function (err) {
            helpers.log('ajax error', err);
          },
          complete: function () {
            $('.sf-summary').removeClass('loader');
          },
        });
      } else {
        sfPackage.updateState('sf_sub_delivery_postcode', null, false);
      }
    });

    $('[name="sf_sub_delivery_shipping_zone"]').on('change', function () {
      const zone = $('[name="sf_sub_delivery_shipping_zone"]');

      $('[name="sf_sub_delivery_time"]').each(function () {
        this.checked = false;
      });

      $('[name="sf_sub_delivery_time"]').trigger('change');

      if ([1, 2].includes(parseInt(zone.val()))) {
        sfPackage.updateState(
          'sf_sub_delivery_shipping_zone',
          parseInt(zone.val()),
          true
        );
      } else {
        sfPackage.updateState(
          'sf_sub_delivery_shipping_zone',
          parseInt(zone.val()),
          false
        );
      }
    });

    $('[name="sf_sub_delivery_address"]').on('change', function () {
      const address = $('[name="sf_sub_delivery_address"]');
      sfPackage.updateState(
        'sf_sub_delivery_address',
        address.val().length ? address.val() : null,
        address.val() ? true : false
      );
    });

    $('[name="sf_sub_delivery_address_a2"]').on('change', function () {
      const address_a2 = $('[name="sf_sub_delivery_address_a2"]');
      sfPackage.updateState(
        'sf_sub_delivery_address_a2',
        address_a2.val().length ? address_a2.val() : null,
        address_a2.val() ? true : false
      );
    });

    $('[name="sf_sub_delivery_address_a5"]').on('change', function () {
      const address_a5 = $('[name="sf_sub_delivery_address_a5"]');
      sfPackage.updateState(
        'sf_sub_delivery_address_a5',
        address_a5.val().length ? address_a5.val() : null,
        address_a5.val() ? true : false
      );
    });

    $('[name="sf_sub_delivery_building_type"]').on('change', function () {
      const building_key = $(
        '[name="sf_sub_delivery_building_type"]:checked'
      ).val();
      $('[name="sf_sub_delivery_time"]').each(function () {
        this.checked = false;
      });
      $('[name="sf_sub_delivery_time"]').trigger('change');

      if (['private'].includes(building_key)) {
        sfPackage.validate.sf_sub_delivery_info_territory_dog = false;
        sfPackage.validate.sf_sub_delivery_info_box_placement_answer = false;
      } else {
        delete sfPackage.validate.sf_sub_delivery_info_territory_dog;
        delete sfPackage.validate.sf_sub_delivery_info_box_placement_answer;
      }

      if (['apartment', 'business', 'other'].includes(building_key)) {
        sfPackage.validate.sf_sub_delivery_info_entrance_limited = false;
      } else {
        delete sfPackage.validate.sf_sub_delivery_info_entrance_limited;
      }

      if (['apartment', 'business'].includes(building_key)) {
        sfPackage.validate.sf_sub_delivery_info_floor_number_answer = false;
      } else {
        delete sfPackage.validate.sf_sub_delivery_info_floor_number_answer;
      }

      sfPackage.updateState(
        'sf_sub_delivery_building_type',
        building_key,
        true
      );
    });

    $('[name="sf_sub_delivery_time"]').on('change', function () {
      const selected = $('[name="sf_sub_delivery_time"]:checked');
      if (selected.length) {
        sfPackage.updateState(
          'sf_sub_delivery_time',
          selected.data('value'),
          true
        );
        sfPackage.updateState('sf_sub_delivery_time_val', selected.val());
      } else {
        sfPackage.updateState('sf_sub_delivery_time', null, false);
        sfPackage.updateState('sf_sub_delivery_time_val', null);
      }

      if (selected.val() == 'box') {
        sfPackage.validate.sf_sub_delivery_info_box_placement_answer = false;
      } else {
        delete sfPackage.validate.sf_sub_delivery_info_box_placement_answer;
      }
    });

    $('.sf-delivery-info [type="radio"]').on('change', function () {
      const name = $(this).attr('name');
      const value = $(this).val();
      if (value == 'yes') {
        $(`#${name}_answer_field`).removeClass('hidden');
      } else if (value == 'no') {
        $(`#${name}_answer_field`).addClass('hidden');
      }
    });

    $('[name="sf_sub_delivery_info_territory_limited"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value == 'yes') {
          sfPackage.validate.sf_sub_delivery_info_territory_limited_answer = false;
        } else {
          delete sfPackage.validate
            .sf_sub_delivery_info_territory_limited_answer;
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_territory_limited_answer"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value.length) {
          sfPackage.updateState(
            'sf_sub_delivery_info_territory_limited_answer',
            null,
            true
          );
        } else {
          sfPackage.updateState(
            'sf_sub_delivery_info_territory_limited_answer',
            null,
            false
          );
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_territory_dog"]').on('change', function () {
      const value = $(this).val();
      if (value == 'yes') {
        sfPackage.validate.sf_sub_delivery_info_territory_dog_answer = false;
      } else {
        delete sfPackage.validate.sf_sub_delivery_info_territory_dog_answer;
      }

      sfPackage.validateOrder();
    });

    $('[name="sf_sub_delivery_info_territory_dog_answer"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value.length) {
          sfPackage.updateState(
            'sf_sub_delivery_info_territory_dog_answer',
            null,
            true
          );
        } else {
          sfPackage.updateState(
            'sf_sub_delivery_info_territory_dog_answer',
            null,
            false
          );
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_entrance_limited"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value == 'yes') {
          sfPackage.validate.sf_sub_delivery_info_entrance_limited_answer = false;
        } else {
          delete sfPackage.validate
            .sf_sub_delivery_info_entrance_limited_answer;
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_entrance_limited_answer"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value.length) {
          sfPackage.updateState(
            'sf_sub_delivery_info_entrance_limited_answer',
            null,
            true
          );
        } else {
          sfPackage.updateState(
            'sf_sub_delivery_info_entrance_limited_answer',
            null,
            false
          );
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_box_placement_answer"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value.length) {
          sfPackage.updateState(
            'sf_sub_delivery_info_box_placement_answer',
            null,
            true
          );
        } else {
          sfPackage.updateState(
            'sf_sub_delivery_info_box_placement_answer',
            null,
            false
          );
        }

        sfPackage.validateOrder();
      }
    );

    $('[name="sf_sub_delivery_info_floor_number_answer"]').on(
      'change',
      function () {
        const value = $(this).val();
        if (value.length) {
          sfPackage.updateState(
            'sf_sub_delivery_info_floor_number_answer',
            null,
            true
          );
        } else {
          sfPackage.updateState(
            'sf_sub_delivery_info_floor_number_answer',
            null,
            false
          );
        }

        sfPackage.validateOrder();
      }
    );
  },

  preFillData: function () {
    $('[name="sf_sub_reference"]').trigger('change');

    if (sfPackage.subConfig) {
      sfPackage.preFillConfigData();
    }
  },

  preFillConfigData: function () {
    const conf = sfPackage.subConfig;

    if (conf.package.period) {
      $(`#sf_attribute_pa_periood_${conf.package.period.slug}`)
        .prop('checked', 'checked')
        .trigger('change');
    }

    // Extras

    [
      'extras_no_meat',
      'extras_no_fish',
      'extras_no_lactose',
      'extras_instruments',
    ].map((item) => {
      if (item in conf.kitchen && conf.kitchen[item] == true) {
        $(`[name="sf_sub_${item}"]`)
          .prop('checked', 'checked')
          .trigger('change');
      }
    });

    // Contact

    ['first_name', 'last_name', 'phone', 'email'].map((item) => {
      if (conf.contact[item].length) {
        $(`[name="sf_sub_contact_${item}"]`)
          .val(conf.contact[item])
          .trigger('change');
      }
    });

    // Delivery

    [
      'postcode',
      'address',
      'address_a1',
      'address_a2',
      'address_a3',
      'address_a4',
      'address_a5',
      'address_a6',
      'address_a7',
      'address_a8',
      'shipping_zone',
    ].map((item) => {
      if (conf.delivery[item]) {
        if (item == 'address') {
          $('.regio-address-search')
            .children('.regio-address-search-input')
            .val(conf.delivery[item])
            .trigger('addressresultitemchange');
        }
        $(`[name="sf_sub_delivery_${item}"]`)
          .val(conf.delivery[item])
          .trigger('change');
      }
    });

    if (conf.delivery.building) {
      $(`#delivery_building_${conf.delivery.building}`)
        .prop('checked', 'checked')
        .trigger('change');

      setTimeout(() => {
        if (conf.delivery.time) {
          $(`#sf_sub_delivery_time_${conf.delivery.time}`)
            .prop('checked', 'checked')
            .trigger('change');
        }

        setTimeout(() => {
          ['territory_limited', 'territory_dog', 'entrance_limited'].map(
            (item) => {
              console.log(
                conf.delivery[item],
                `#sf_sub_delivery_info_${item}_yes`,
                $(`#sf_sub_delivery_info_${item}_yes`),
                $(`#sf_sub_delivery_info_${item}_no`)
              );
              if (item in conf.delivery && conf.delivery[item] == true) {
                $(`#sf_sub_delivery_info_${item}_yes`)
                  .prop('checked', 'checked')
                  .trigger('change');
                $(`[name="sf_sub_delivery_info_${item}_answer"]`)
                  .val(conf.delivery[item])
                  .trigger('change');
              } else {
                $(`#sf_sub_delivery_info_${item}_no`)
                  .prop('checked', 'checked')
                  .trigger('change');
              }
            }
          );

          if (conf.delivery.box_placement) {
            $('[name="sf_sub_delivery_info_box_placement_answer"')
              .val(conf.delivery.box_placement)
              .trigger('change');
          }

          if (conf.delivery.comments) {
            $('[name="sf_sub_delivery_info_comments_answer"')
              .val(conf.delivery.comments)
              .trigger('change');
          }
        }, 1000);
      }, 3000);
    }

    setTimeout(() => {
      const element = document.getElementById('sf-product-dates');
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 4500);
  },

  parseCampaigns: function (campaigns) {
    Object.values(campaigns).forEach((campaign, index) => {
      if (Object.keys(sfPackage.state.campaigns).includes(campaign.code))
        return;

      const name = document.createTextNode(campaigns[index].name);

      let liNode = document.createElement('li');
      liNode.classList.add('campaign-codes__item');
      liNode.dataset.code = campaigns[index].code;
      liNode.appendChild(name);

      let inputNode = document.createElement('input');
      inputNode.id = 'sf-product-campaign-code-' + campaigns[index].code;
      inputNode.type = 'checkbox';
      inputNode.name = 'sf_campaign_codes[]';
      inputNode.value = campaigns[index].code;
      inputNode.checked = 1;

      let labelNode = document.createElement('label');
      labelNode.htmlFor = 'sf-product-campaign-code-' + campaigns[index].code;

      liNode.appendChild(inputNode);
      liNode.appendChild(labelNode);

      sfPackage.bootstrapCampaign(liNode, campaigns[index]);
    });

    sfPackage.maybeUpdateEndDate();
  },

  bootstrapCampaign: function (node, campaign) {
    $('#sf-product-campaign-codes').append(node);
    sfPackage.state.campaigns[campaign.code] = campaign;
    $(node)
      .find('input[type="checkbox"]')
      .on('change', (event) => {
        if (event.currentTarget.checked == false) {
          const campaignName = $(event.currentTarget).val();
          delete sfPackage.state.campaigns[campaignName];
          $(event.currentTarget).closest('li').remove();
          sfPackage.maybeUpdateEndDate();
        }
      });
  },

  checkExistingCampaigns: function () {
    const campaigns = sfPackage.state.campaigns;
    helpers.log('checking campaigns', campaigns, Object.keys(campaigns).length);
    if (!Object.keys(campaigns).length) return;

    Object.values(campaigns).forEach((campaign) => {
      helpers.log(
        644,
        campaign,
        campaign.periods,
        sfPackage.pa_periood,
        campaign.periods.includes(sfPackage.pa_periood)
      );
      if (!campaign.periods.includes(sfPackage.pa_periood)) {
        delete sfPackage.state.campaigns[campaign.code];
        $('#sf-product-campaign-codes')
          .find('li[data-code="' + campaign.code + '"]')
          .remove();
      }
    });

    helpers.log(sfPackage.state.campaigns);
  },

  updateEndDate: function (start_date, days) {
    $.ajax({
      type: 'POST',
      url: window.smart.ajax.url,
      dataType: 'json',
      data: {
        action: 'sf_get_sum_period_data',
        args: {
          start_date: start_date,
          days_initial: days,
        },
      },
      beforeSend: function () {
        $('.sf-summary').addClass('loader');
        $('.period-selector').addClass('loader');
      },
      success: function (response) {
        // console.log('ajax success', response);
        if (response.dates.end.length) {
          sfPackage.updateState('sf_sub_end_date', response.dates.end, true);
        }
        sfPackage.periodDatepicker.activeDates = response.dates.actives;
        sfPackage.periodDatepicker.instance.datepicker('refresh');
      },
      error: function (err) {
        helpers.log('ajax error', err);
      },
      complete: function () {
        $('.sf-summary').removeClass('loader');
        $('.period-selector').removeClass('loader');
        sfPackage.validateOrder();
      },
    });
  },

  maybeUpdateEndDate: function () {
    if (sfPackage.state.sf_sub_start_date && sfPackage.state.days) {
      sfPackage.updateEndDate(
        sfPackage.state.sf_sub_start_date,
        sfPackage.getCombinedTotalDays()
      );
    }
  },

  updateState: function (key, value, valid = null) {
    sfPackage.state[key] = value;

    if (valid == true) {
      sfPackage.validate[key] = true;
    } else if (valid == false) {
      sfPackage.validate[key] = false;
    }

    if (sfPackage.state.sf_sub_reference) {
      sfPackage.updateSummary(
        'sf_sub_reference',
        '#' + sfPackage.state.sf_sub_reference
      );
    }

    if (sfPackage.state.sf_attribute_pa_periood) {
      $('.campaign-code').removeClass('campaign-code--disabled');
      $('.period-selector').removeClass('period-selector--disabled');
      $('.address-selector').removeClass('address-selector--disabled');
      sfPackage.updateSummary(
        'sf_attribute_pa_periood',
        sfPackage.state.sf_attribute_pa_periood
      );
    } else {
      $('.campaign-code').addClass('campaign-code--disabled');
      $('.period-selector').addClass('period-selector--disabled');
      $('.address-selector').addClass('address-selector--disabled');
    }

    if (sfPackage.state.sf_sub_start_date) {
      sfPackage.updateSummary(
        'sf_sub_start_date',
        dayjs(sfPackage.state.sf_sub_start_date).format('DD.MM.YYYY')
      );
    }

    if (sfPackage.state.sf_sub_end_date) {
      sfPackage.updateSummary(
        'sf_sub_end_date',
        dayjs(sfPackage.state.sf_sub_end_date).format('DD.MM.YYYY')
      );
    }

    if (
      sfPackage.state.sf_sub_contact_first_name &&
      sfPackage.state.sf_sub_contact_last_name &&
      sfPackage.state.sf_sub_contact_phone &&
      sfPackage.state.sf_sub_contact_email
    ) {
      sfPackage.updateSummary(
        'sf_sub_contact',
        `${sfPackage.state.sf_sub_contact_first_name} ${sfPackage.state.sf_sub_contact_last_name}, ${sfPackage.state.sf_sub_contact_phone}, ${sfPackage.state.sf_sub_contact_email}`
      );
    }

    if (sfPackage.state.sf_sub_delivery_address) {
      $('#summary-sf_sub_delivery_address-content')
        .html(sfPackage.state.sf_sub_delivery_address)
        .removeClass('hidden');
      $('.building-selector').removeClass('building-selector--disabled');
    } else {
      $('.building-selector').addClass('building-selector--disabled');
      $('#summary-sf_sub_delivery_address-content')
        .html('-')
        .addClass('hidden');
      $('#summary-sf_sub_delivery_address-state')
        .removeClass('sf-summary__item-state--error')
        .removeClass('sf-summary__item-state--ok');
    }

    if (sfPackage.state.sf_sub_delivery_postcode) {
      if (sfPackage.validate.sf_sub_delivery_postcode) {
        $('#no-delivery-options-available-notice').addClass('hidden');
        $('#summary-sf_sub_delivery_address-state')
          .removeClass('sf-summary__item-state--error')
          .addClass('sf-summary__item-state--ok');
      } else {
        $('#no-delivery-options-available-notice').removeClass('hidden');
        $('#summary-sf_sub_delivery_address-state')
          .removeClass('sf-summary__item-state--ok')
          .addClass('sf-summary__item-state--error');
      }
    }

    if (sfPackage.state.sf_sub_delivery_building_type) {
      $('[data-show-for-building]').each(function (key, el) {
        let buildings = $(el).data('show-for-building');
        buildings = buildings.split(',');
        if (buildings.includes(sfPackage.state.sf_sub_delivery_building_type)) {
          $(el).removeClass('hidden');
        } else {
          $(el).addClass('hidden');
        }
      });
      $('.delivery-additional').removeClass('delivery-additional--disabled');
    } else {
      $('.delivery-additional').addClass('delivery-additional--disabled');
    }

    if (
      sfPackage.state.sf_sub_delivery_building_type &&
      sfPackage.state.sf_sub_delivery_shipping_zone
    ) {
      if (sfPackage.state.sf_sub_delivery_shipping_zone == 1) {
        $('#sf-sub-delivery-box').removeClass('hidden');
        $('#sf-sub-delivery-box-1').removeClass('hidden');
        $('#sf-sub-delivery-box-2').addClass('hidden');
        $('#sf-sub-delivery-courier').removeClass('hidden');
        $('#sf-sub-delivery-box-notice').addClass('hidden');
      } else if (sfPackage.state.sf_sub_delivery_shipping_zone == 2) {
        $('#sf-sub-delivery-box').removeClass('hidden');
        $('#sf-sub-delivery-box-1').addClass('hidden');
        $('#sf-sub-delivery-box-2').removeClass('hidden');
        $('#sf-sub-delivery-courier').addClass('hidden');
        $('#sf-sub-delivery-box-notice').removeClass('hidden');
      } else {
        $('#sf-sub-delivery-box').addClass('hidden');
        $('#sf-sub-delivery-box-1').addClass('hidden');
        $('#sf-sub-delivery-box-2').addClass('hidden');
        $('#sf-sub-delivery-courier').addClass('hidden');
        $('#sf-sub-delivery-box-notice').addClass('hidden');
      }
    } else {
      $('#sf-sub-delivery-box').addClass('hidden');
      $('#sf-sub-delivery-courier').addClass('hidden');
      $('#sf-sub-delivery-box-notice').addClass('hidden');
    }

    $('[data-show-for-time]').each(function (key, el) {
      let times = $(el).data('show-for-time');
      times = times.split(',');
      if (times.includes(sfPackage.state.sf_sub_delivery_time_val)) {
        $(el).removeClass('hidden');
      } else {
        $(el).addClass('hidden');
      }
    });

    if (sfPackage.state.sf_sub_delivery_time) {
      sfPackage.updateSummary(
        'sf_sub_delivery_time',
        sfPackage.state.sf_sub_delivery_time
      );
    } else {
      sfPackage.updateSummary('sf_sub_delivery_time', '-', true);
    }

    if (sfPackage.validateTimeout) clearTimeout(sfPackage.validateTimeout);
    sfPackage.validateTimeout = setTimeout(sfPackage.validateOrder, 1500);
  },

  updateSummary: function (key, value, reset = false) {
    $(`#summary-${key}-content`).html(value);

    if (!reset) {
      $(`#summary-${key}-state`).addClass('sf-summary__item-state--ok');
      $(`#summary-${key}-content`).removeClass('hidden');
    } else {
      $(`#summary-${key}-state`).removeClass('sf-summary__item-state--ok');
      $(`#summary-${key}-content`).addClass('hidden');
    }
  },

  validateOrder: function () {
    let invalid = [];

    for (const [key, value] of Object.entries(sfPackage.validate)) {
      const checked = $(`[name="${key}"]`).is(':checked');
      if (!checked) {
        if (value == false) {
          invalid.push(key);
        }
      }
    }

    let infoIsValid = true;
    for (const [key] of Object.entries(sfPackage.validate)) {
      if (!key.startsWith('sf_sub_delivery_info_')) continue;
      if (invalid.includes(key)) {
        infoIsValid = false;
      }
    }
    if (infoIsValid) {
      $('#summary-sf_sub_delivery_info-state').addClass(
        'sf-summary__item-state--ok'
      );
    } else {
      $('#summary-sf_sub_delivery_info-state').removeClass(
        'sf-summary__item-state--ok'
      );
    }

    if (invalid.length) {
      helpers.log('[validate errors]', invalid);
      $('#sf-product-configuration-not-finished').removeClass('hidden');
      $('#single_add_to_cart_button').attr('disabled', true);
    } else {
      $('#sf-product-configuration-not-finished').addClass('hidden');
      $('#single_add_to_cart_button').attr('disabled', false);
    }

    sfPackage.validateTimeout = null;
  },
};
