export const objects = {
  document: $('html'),
  main: $('main.main'),
  overlay: $('.overlay'),
  navTrigger: $('.nav-trigger'),
  searchTrigger: $('.search-trigger'),
  searchWrap: $('.search-wrap'),
  mainHeader: $('header.main-header'),
  offCanvasMenu: $('.off-canvas-menu'),
  mainMenu: $('.menu'),
  adminbar: $('#wpadminbar'),
};

export const settings = {
  wp: {
    env: window.smart.wp.env,
  },
};

export const config = {};
