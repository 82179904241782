export const caloriescal = {
  isInitialized: false,

  init: function () {
    // Init lozad.js
    if (!this.isInitialized) {
      if ($('#calcalc').length) {
        this.triggerEvents();
        this.InitValues();
        this.setLang();
      }
    }
  },

  triggerEvents: function () {
    $('#calcalc').on('input change', () => {
      this.calculateCalories();
    });
    $('#calcalc').submit((event) => {
      event.preventDefault();
      this.calculateCalories();
    });
  },

  InitValues: function () {
    $('#Weight').val(55);
    $('#Height').val(160);
    $('#Age').val(25);
    this.calculateCalories();
  },

  calculateCalories: function () {
    var gender = $('#Gender').val();
    var weight = parseFloat($('#Weight').val()) * 2.2;
    var height = parseFloat($('#Height').val());
    if (height < 5) {
      height = height * 100;
    }
    height = height / 2.54;
    var actFactor = parseFloat($('#ActLevel').val());
    var age = parseFloat($('#Age').val());
    var BAV = 0;
    if (!isNaN(weight) && !isNaN(height) && !isNaN(age)) {
      //female: 655 + (4.35 * Weight lbs) + (4.7 * Height in) - (4.7 * Age)";
      //male: 66 + (6.23 * Weight lbs) + (12.7 * Height in) - (6.8 * Age)";
      if (gender == 'f') {
        BAV = Math.round(655 + 4.35 * weight + 4.7 * height - 4.7 * age);
      } else {
        BAV = Math.round(66 + 6.23 * weight + 12.7 * height - 6.8 * age);
      }
      $('#BAV').html(BAV);
      $('#AvgCalDay').html(Math.round(BAV * actFactor));
      $('#AvgCalHour').html(Math.round((BAV * actFactor) / 24));
      // console.log(BAV,BAV*actFactor,Math.round(BAV*actFactor),Math.round(BAV*actFactor)/24)
    }
  },

  setLang: function () {
    let lang = $('html').attr('lang');
    lang = lang == 'en-US' ? 'en' : lang;
    lang = lang == 'ru-RU' ? 'ru' : lang;

    const translations = {
      CALORIE_CALC: {
        TITLE: {
          et: 'Kalorikalkulaator',
          en: 'Calorie calculator',
          ru: 'Калькулятор калорий',
        },
        BUTTON_LABEL: {
          et: 'Arvuta',
          en: 'Calculate',
          ru: 'Подсчитайте',
        },
        GENDER: {
          TITLE: {
            et: 'Sugu:',
            en: 'Gender:',
            ru: 'Пол:',
          },
          MALE: {
            et: 'Mees',
            en: 'Male',
            ru: 'Мужчина',
          },
          FEMALE: {
            et: 'Naine',
            en: 'Female',
            ru: 'Женщина',
          },
        },
        WEIGHT: {
          et: 'Kaal (kg):',
          en: 'Weight (kg):',
          ru: 'Вес (кг):',
        },
        HEIGHT: {
          et: 'Pikkus (cm):',
          en: 'Height (cm):',
          ru: 'Рост (см):',
        },
        AGE: {
          et: 'Vanus (aastat):',
          en: 'Age (years):',
          ru: 'Возраст (годы):',
        },
        PHYSICAL: {
          TITLE: {
            et: 'Füüsiline koormus:',
            en: 'Physical activity:',
            ru: 'Физическая нагрузка:',
          },
          LEVEL0: {
            et: 'Istuv eluviis (minimaalne kehaline aktiivsus)',
            en: 'Sedentary lifestyle (minimal physical activity)',
            ru: 'Сидячий житель (минимальная физическая активность)',
          },
          LEVEL1: {
            et: 'Kerge kehaline aktiivsus (1-3 kerget treeningkorda nädalas)',
            en: 'Light physical activity (1-3 light workouts per week)',
            ru: 'Легкая физическая активность (1-3 легкие тренировки в неделю)',
          },
          LEVEL2: {
            et: 'Keskmine kehaline aktiivsus (3-5 treeningkorda nädalas)',
            en: 'Average physical activity (1-3 workouts per week)',
            ru: 'Средняя физическая активность (1-3 тренировки в неделю)',
          },
          LEVEL3: {
            et: 'Väga aktiivne (6-7 treeningkorda nädalas / rasked treeningud)',
            en: 'Very high (6-7 workouts per week / tough workouts)',
            ru: 'Очень активный (6-7 тренировок в неделю / жесткие тренировки)',
          },
          LEVEL4: {
            et: 'Üliaktiivne (igapäevased treeningud / 2 x päevas treeningud / maratonid / raske füüsiline töö)',
            en: 'Extreme (working out every day or several times a day / marathons / tough physical work)',
            ru: 'Чрезвычайно (работает каждый день или несколько раз в день / марафоны / тяжелая физическая работа)',
          },
        },
        RESULTS: {
          BMR: {
            TITLE: {
              et: 'Baasainevahetus (kcal):',
              en: 'Basic metabolism (kcal):',
              ru: 'Базовый обмен веществ (ккал)',
            },
            P1: {
              et: 'Ööpäevast energiahulka, mis on vajalik Sinu keha eluliste funktsioonide tagamiseks, nimetatakse baasainevahetuseks (BAV). See on energia hulk, mille Sinu organism põletab päevas ära ka igasuguse füüsilise koormuse puudumisel.',
              en: 'The amount of energy the body requires for it to function during a twenty-four hour period is called Basic Metabolism (BM). This is the amount of energy that the body burns during a day without any extra physical activity.',
              ru: 'Базовым обменом веществ (БОВ)  называется суточное количество энергии, которое необходимо для поддержания жизненно необходимых функций организма. Это то количество энергии, которое Ваш организм сжигает за день без всякой физической нагрузки.',
            },
            P2: {
              et: 'Selle, mida Sa sööd ja jood, muudab keha ainevahetuse käigus energiaks. Isegi siis, kui Sa füüsiliselt midagi ei tee, kaotab Sinu keha energiat, sest see on vajalik organismi ülalhoidmiseks - neid kaloreid on vaja hingamiseks, südame ja vereringe tööks, rakkude uuendamiseks, maksa ja neerude talitluseks ning organismis toimuva mis tahes muu reaktsiooni tagamiseks.',
              en: 'Everything a person eats and drinks converts into energy in the body’s metabolic system. Even if a person is not physically active, the body loses energy to maintain bodily functions – these calories are needed for breathing, functioning of the heart, bloodstream, liver and kidneys, renewal of cells, different physical reactions and everything else that is going on in the body.',
              ru: 'То, что Вы едите и пьете,  в результате обмена веществ, в организме  превращается в энергию. Даже тогда, когда Вы физически ничего не делаете, организм теряет энергию, поскольку она необходима для обеспечения его жизнедеятельности– эти калории необходимы для поддержки дыхания, работы сердца и кровообращения, обновления клеток, работы печени и почек и для различных реакций, происходящих внутри организма, для общего обмена веществ.',
            },
            P3: {
              et: 'Teades oma BAV-d, on võimalik kindlaks määrata, kui palju kaloreid on Sul minimaalselt päevas tarvis toiduga saada.',
              en: 'Knowing your BM it is possible to determine the lowest amount of calories you need to get from food in a day.',
              ru: 'Зная свой БОВ, можно точно определить ежедневное минимально необходимое количество калорий, которое Вы должны получить с пищей.',
            },
          },
          DAILY: {
            TITLE: {
              et: 'Päevane kalorikulu (kcal):',
              en: 'Calories spent in a day (kcal):',
              ru: 'Дневной расход калорий (ккал):',
            },
            P1: {
              et: 'Nii palju kaloreid põletab Sinu organism päevas, võttes arvesse ka Su füüsilist koormust.',
              en: 'This is the amount of calories your body burns in a day, taking into account your physical activities.',
              ru: 'Столько калорий сжигает Ваш организм за день, учитывая  физическую нагрузку.',
            },
            P2: {
              et: 'Kaalu hoidmiseks ei tohiks Sa antud kalorite hulka ületada.',
              en: 'In order to maintain your weight you must not exceed the given amount of calories.',
              ru: 'Для поддержания веса Вы не должны превышать данное количество калорий.',
            },
            P3: {
              et: 'Kaalu alandamiseks võiksid tarbida vähem kaloreid, kui see tulemus siin (ca 200-300 kcal).',
              en: 'In order to lower your weight you should consume fewer calories than the given amount (approximately 200-300 kcal)',
              ru: 'Для снижения веса нужно потреблять меньше калорий, чем этот результат ( на 200-300 ккал).',
            },
            P4: {
              et: 'NB! Kindlasti ei tohi päevast kalorite hulka lasta alla BAV tulemuse!',
              en: 'NB! You have to keep in mind not to lower the amount of calories consumed in a day below your BM!',
              ru: 'NB! Нельзя ежедневное количество калорий уменьшить ниже показателя БОВ.',
            },
            P5: {
              et: 'NB! Kui oled rase või imetav ema, lisa siia sellest tulenev lisaenergiavajadus:',
              en: 'NB! If you are pregnant or a breastfeeding mother then an additional amount of calories need to be added accordingly:',
              ru: 'NB! Беременная женщина и кормящая мать должна добавить дополнительное количество энергии:',
            },
            P6: {
              et: 'Kui raseduse tõttu füüsiline aktiivsus vähenes, siis lisaenergiavajadus on 0',
              en: 'If your physical activity has diminished due to pregnancy then the amount of extra energy is 0.',
              ru: 'Если во время беременности снизилась физическая активность, тогда  дополнительной энергии не требуется -0',
            },
            P7: {
              et: 'Raseduse-eelse füüsilise aktiivsuse säilimisel raseduse ajal on lisaenergiavajadus kuni 300 kcal päevas',
              en: 'If you maintain your pre-pregnancy rate of physical activity then the amount of extra energy is up to 300 kcal per day.',
              ru: 'Чтобы сохранить физическую активность, которая была до беременности, надо добавить дополнительно 300 ккал в день.',
            },
            P8: {
              et: 'Imetavate emade lisaenergiavajadus on 500-650 kcal päevas',
              en: 'Breastfeeding mothers need 500-560 kcal extra energy per day.',
              ru: 'Кормящая мать должна прибавить дополнительно 500-650 ккал в день',
            },
            P9: {
              et: 'Kindlasti konsulteeri täpset energiavajadust enne tellimuse esitamist oma arstiga!',
              en: 'Definitely consult your specific energy need with your doctor before placing the order!',
              ru: 'До оформления заказа обязательно необходимо проконсультироваться со своим врачом, чтобы знать точное количество необходимой энергии!',
            },
          },
        },
      },
    };

    const $calcalc = $('#calcalc');

    $calcalc
      .find("select[name='Gender']")
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.GENDER.TITLE[lang]);
    $calcalc
      .find("select[name='Gender'] option[value='f']")
      .html(translations.CALORIE_CALC.GENDER.FEMALE[lang]);
    $calcalc
      .find("select[name='Gender'] option[value='m']")
      .html(translations.CALORIE_CALC.GENDER.MALE[lang]);

    $calcalc
      .find("input[name='Weight']")
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.WEIGHT[lang]);
    $calcalc
      .find("input[name='Height']")
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.HEIGHT[lang]);
    $calcalc
      .find("input[name='Age']")
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.AGE[lang]);

    $calcalc
      .find("select[name='ActLevel']")
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.PHYSICAL.TITLE[lang]);
    $calcalc.find("select[name='ActLevel']").find('option')[0].innerHTML =
      translations.CALORIE_CALC.PHYSICAL.LEVEL0[lang];
    $calcalc.find("select[name='ActLevel']").find('option')[1].innerHTML =
      translations.CALORIE_CALC.PHYSICAL.LEVEL1[lang];
    $calcalc.find("select[name='ActLevel']").find('option')[2].innerHTML =
      translations.CALORIE_CALC.PHYSICAL.LEVEL2[lang];
    $calcalc.find("select[name='ActLevel']").find('option')[3].innerHTML =
      translations.CALORIE_CALC.PHYSICAL.LEVEL3[lang];
    $calcalc.find("select[name='ActLevel']").find('option')[4].innerHTML =
      translations.CALORIE_CALC.PHYSICAL.LEVEL4[lang];

    $calcalc
      .find('.bank_calculator')
      .find('input[type="submit"]')
      .attr('value', translations.CALORIE_CALC.BUTTON_LABEL[lang]);

    $calcalc
      .find('.kalkul-tabel')
      .find('#BAV')
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.RESULTS.BMR.TITLE[lang]);
    $calcalc
      .find('.kalkul-tabel')
      .find('#BAV')
      .closest('tr')
      .next()
      .find('td p')[0].innerHTML =
      translations.CALORIE_CALC.RESULTS.BMR.P1[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#BAV')
      .closest('tr')
      .next()
      .find('td p')[1].innerHTML =
      translations.CALORIE_CALC.RESULTS.BMR.P2[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#BAV')
      .closest('tr')
      .next()
      .find('td p')[2].innerHTML =
      translations.CALORIE_CALC.RESULTS.BMR.P3[lang];

    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .parent()
      .prev()
      .find('strong')
      .html(translations.CALORIE_CALC.RESULTS.DAILY.TITLE[lang]);
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('p')[0].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P1[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('li')[0].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P2[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('li')[1].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P3[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('p')[1].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P4[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('p')[2].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P5[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('li')[2].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P6[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('li')[3].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P7[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('li')[4].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P8[lang];
    $calcalc
      .find('.kalkul-tabel')
      .find('#AvgCalDay')
      .closest('tr')
      .next()
      .find('p ')[3].innerHTML =
      translations.CALORIE_CALC.RESULTS.DAILY.P9[lang];
  },
};
