/**
 * External Dependencies
 */
import { router } from 'js-dom-router';
import 'jquery';

/**
 * DOM-based routing
 */
import common from './routes/common';
import productSingle from './routes/productSingle';
import myAccount from './routes/myAccount';
import mySubscription from './routes/mySubscription';

/**
 * Set up DOM router
 *
 * .on(<name of body class>, callback)
 *
 * See: {@link http://goo.gl/EUTi53 | Markup-based Unobtrusive Comprehensive DOM-ready Execution} by Paul Irish
 */
router
  .on('smart', common)
  .on('single-product', productSingle)
  .on('woocommerce-account', myAccount)
  .on('page-my-subscription', mySubscription)
  .ready();
