import { menu } from '../modules/menu';
import { packages } from '../modules/packages';
import { testimonials } from '../modules/testimonials';
import { caloriescal } from '../modules/caloriescal';
import { accordion } from '../modules/accordion';

/**
 * Common
 */
export default () => {
  // Set smart object
  let smart = window.smart;

  // Init Modules
  smart.menu = menu;
  menu.init();

  document.querySelectorAll('a.scroll-to').forEach((element) => {
    if (!element.hash) return;
    const target = document.querySelectorAll(element.hash)[0];
    element.addEventListener('click', function (e) {
      e.preventDefault();
      target.scrollIntoView({ behavior: 'smooth' });
    });
  });

  testimonials.init();

  packages.init();

  caloriescal.init();

  accordion.init();
};
