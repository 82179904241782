import RegioAddressSearchBox from './RegioAddressSearchBox';
import SmartDatepicker from '../../classes/SmartDatepicker';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import { helpers } from '../helpers';

export const mySub = {
  isInitialized: false,
  regioSearchBox: null,
  unpauseDatepicker: null,
  calendarDatepicker: null,

  deliveryValues: {
    dates: false,
    postcode: false,
    time: false,
    comments: false,
  },

  deliveryDatepicker: null,
  deliveryIssues: {},

  pauseDatepicker: null,
  minPauseChangeDate: null,
  minDeliveryChangeDate: null,

  init: function () {
    if (!this.isInitialized) {
      this.bootstrap();
      dayjs.extend(minMax);
      this.datepickerUnpause();
      this.datepickerCalendar();
      this.datepickerDelivery();
      this.datepickerPause();
      this.triggerEvents();
    }
  },

  bootstrap: function () {
    if ($('.regio-address-search-input').length) {
      mySub.regioSearchBox = new RegioAddressSearchBox();
    }

    // Set min pause change date
    if (dayjs().isBefore(dayjs().startOf('day').add(16, 'h'))) {
      mySub.minPauseChangeDate = dayjs()
        .startOf('day')
        .add(2, 'd')
        .format('YYYY-MM-DD');
    } else {
      mySub.minPauseChangeDate = dayjs()
        .startOf('day')
        .add(3, 'd')
        .format('YYYY-MM-DD');
    }

    // Set min delivery change date
    if (dayjs().isBefore(dayjs().startOf('day').add(12, 'h'))) {
      mySub.minDeliveryChangeDate = dayjs()
        .startOf('day')
        .add(1, 'd')
        .format('YYYY-MM-DD');
    } else {
      mySub.minDeliveryChangeDate = dayjs()
        .startOf('day')
        .add(2, 'd')
        .format('YYYY-MM-DD');
    }
  },

  datepickerUnpause: function () {
    const calendarUnpause = $('#sf-sub-calendar-unpause');
    if (!calendarUnpause.length) return;

    mySub.unpauseDatepicker = new SmartDatepicker(calendarUnpause, {
      minDate: mySub.minPauseChangeDate,
    });

    const pauseDates = mySub.unpauseDatepicker.skipDates.map((d) => dayjs(d));
    const dateFrom = dayjs.max(pauseDates).add(1, 'day');
    for (
      let iDate = dateFrom;
      iDate.isBefore(dayjs(mySub.minPauseChangeDate));
      iDate = iDate.add(1, 'day')
    ) {
      const dayOfWeek = iDate.day();
      if (dayOfWeek == 6 || 0) continue;
      mySub.unpauseDatepicker.skipDates.push(iDate.format('YYYY-MM-DD'));
    }

    mySub.unpauseDatepicker.activeDates = [];

    mySub.unpauseDatepicker.instance.datepicker('option', {
      onSelect: function (dateText) {
        $('#sf-my-sub-unpause-date').val(dateText);
        $('#sf-my-sub-unpause-date-preview').val(
          dayjs(dateText).format('DD.MM.YYYY')
        );
        $('#sf-my-sub-unpause-date-submit').prop('disabled', false);
      },
    });
  },

  datepickerCalendar: function () {
    const calendarDatepicker = $('#sf-sub-calendar-datepicker');
    mySub.calendarDatepicker = new SmartDatepicker(calendarDatepicker, {
      minDate: 'today',
      showButtonPanel: false,
    });

    mySub.calendarDatepicker.instance.datepicker('option', {
      onSelect: function (dateText) {
        mySub.pauseDatepicker.addOrRemoveSkipDate(dateText);
        $('#sf-sub-pause-datepicker-dates').val(
          mySub.pauseDatepicker.skipDates
        );
      },
      beforeShowDay: function (date) {
        date = $.datepicker.formatDate('yy-mm-dd', date);
        if (mySub.calendarDatepicker.excludedDates.indexOf(date) != -1) {
          return [false, ''];
        } else if (mySub.calendarDatepicker.skipDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [false, 'sf-pause-past', 'SmartFOOD'];
          } else {
            return [false, 'sf-pause'];
          }
        } else if (mySub.calendarDatepicker.activeDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [false, 'sf-active-past', 'SmartFOOD'];
          } else {
            return [false, 'sf-active', 'SmartFOOD'];
          }
        } else {
          return [false, ''];
        }
      },
    });
  },

  datepickerPause: function () {
    const pauseDatepicker = $('#sf-sub-pause-datepicker');

    mySub.pauseDatepicker = new SmartDatepicker(pauseDatepicker, {
      minDate: mySub.minPauseChangeDate,
    });

    mySub.pauseDatepicker.instance.datepicker('option', {
      onSelect: function (dateText) {
        mySub.pauseDatepicker.addOrRemoveSkipDate(dateText);
        $('#sf-sub-pause-datepicker-dates').val(
          mySub.pauseDatepicker.skipDates
        );
      },
      beforeShowDay: function (date) {
        date = $.datepicker.formatDate('yy-mm-dd', date);

        if (mySub.pauseDatepicker.excludedDates.indexOf(date) != -1) {
          return [false, ''];
        } else if (mySub.pauseDatepicker.skipDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [true, 'sf-pause-past', 'SmartFOOD'];
          } else {
            return [true, 'sf-pause'];
          }
        } else if (mySub.pauseDatepicker.activeDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs())) {
            return [true, 'sf-active-past', 'SmartFOOD'];
          } else {
            return [true, 'sf-active', 'SmartFOOD'];
          }
        } else if (mySub.pauseDatepicker.skipDates.indexOf(date)) {
          return [true, 'ui-state-highlight'];
        } else {
          return [true, ''];
        }
      },
    });
  },

  datepickerDelivery: function () {
    mySub.deliveryDatepicker = new SmartDatepicker(
      $('#sf-my-sub-delivery-datepicker'),
      {
        minDate: mySub.minDeliveryChangeDate,
      }
    );
    mySub.deliveryDatepicker.instance.datepicker('option', {
      onSelect: function (dateText) {
        mySub.deliveryDatepicker.addOrRemoveChangeDate(dateText);
        $('#sf-my-sub-delivery-dates')
          .val(mySub.deliveryDatepicker.changeDates)
          .trigger('change');
      },
      beforeShowDay: function (date) {
        date = $.datepicker.formatDate('yy-mm-dd', date);

        if (mySub.deliveryDatepicker.excludedDates.indexOf(date) != -1)
          return [false, ''];

        let classes = [];
        let canSelect = true;

        if (mySub.deliveryDatepicker.skipDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').isBefore(dayjs()))
            classes.push('sf-pause-past');
          else {
            classes.push('sf-pause');
            canSelect = false;
          }
        } else if (mySub.deliveryDatepicker.activeDates.indexOf(date) != -1) {
          if (dayjs(date, 'YYYY-MM-DD').add(12, 'hour').isBefore(dayjs()))
            classes.push('sf-active-past');
          else classes.push('sf-active');
          if (mySub.deliveryDatepicker.changeDatesQueued.indexOf(date) != -1) {
            classes.push('sf-active--change');
            canSelect = false;
          }
          if (mySub.deliveryDatepicker.changeDates.indexOf(date) != -1)
            classes.push('sf-active--change');
        } else {
          canSelect = false;
        }

        return [canSelect, classes.join(' ')];
      },
    });
  },

  triggerEvents: function () {
    if (mySub.regioSearchBox) {
      mySub.regioSearchBox.input.addEventListener(
        'addressresultitemchange',
        (event) => {
          if (event.detail === null) {
            $('#sf-my-sub-delivery-postcode-validate-message')
              .addClass('hidden')
              .html('');
            $('#sf-my-sub-delivery-postcode').val('');
            return;
          }

          // mySub.clearAddressFields();

          mySub.regioSearchBox.input.value = event.detail.address;

          setTimeout(() => {
            $('#sf-my-sub-delivery-address').val(event.detail.address);
            $('#sf-my-sub-delivery-postcode')
              .val(event.detail.postcode)
              .trigger('change');
          }, 250);
        }
      );
    }

    $('[name="sf_my_sub_delivery_dates"]').on('change', () => {
      if ($('[name="sf_my_sub_delivery_dates"]').val()) {
        this.deliveryValues['dates'] = true;
      } else {
        this.deliveryValues['dates'] = false;
      }

      this.validateDelivery();
    });

    $('[name="sf_my_sub_delivery_postcode"]').on('change', () => {
      const postcode = $('[name="sf_my_sub_delivery_postcode"]').val();
      $.ajax({
        type: 'POST',
        url: window.smart.ajax.url,
        dataType: 'json',
        data: {
          action: 'sf_my_sub_validate_postcode',
          postcode: postcode,
        },
        beforeSend: function () {
          $('.sf-summary').addClass('loading-with-overlay');
          $('#sf-my-sub-regio-search-wrap').addClass('loading-with-overlay');
        },
        success: (response) => {
          if (response.success) {
            $('#sf-my-sub-delivery-postcode-validate-message')
              .addClass('hidden')
              .html('');

            this.deliveryValues['postcode'] = true;
          } else {
            $('#sf-my-sub-delivery-postcode-validate-message')
              .html('')
              .append(response.data.message)
              .removeClass('hidden');
            $('#sf-my-sub-delivery-postcode-validate-message')
              .closest('.shop-accordion__content')
              .trigger('change');

            this.deliveryValues['postcode'] = false;
          }
        },
        error: function (err) {
          alert('Validation error!');
          helpers.log('ajax error', err);
        },
        complete: () => {
          $('.sf-summary').removeClass('loading-with-overlay');
          $('#sf-my-sub-regio-search-wrap').removeClass('loading-with-overlay');

          this.validateDelivery();
        },
      });
    });

    $('[name="sf_my_sub_delivery_time"]').on('change', () => {
      const selected = $('[name="sf_my_sub_delivery_time"]:checked');

      if (selected.length) {
        this.deliveryValues['time'] = true;
      } else {
        this.deliveryValues['time'] = false;
      }

      this.validateDelivery();
    });

    $('[name="sf_my_sub_delivery_comments"]').on('change', () => {
      if ($('[name="sf_my_sub_delivery_comments"]').val()) {
        this.deliveryValues['comments'] = true;
      } else {
        this.deliveryValues['comments'] = false;
      }

      this.validateDelivery();
    });
  },

  addDeliveryIssue: function (key, message = '') {
    this.deliveryIssues[key] = message;
  },

  removeDeliveryIssue: function (key) {
    delete this.deliveryIssues[key];
  },

  validateDelivery: function () {
    $('#sf-my-sub-delivery-postcode').on('change', function () {});

    for (const [key, value] of Object.entries(this.deliveryValues)) {
      if (value) {
        this.removeDeliveryIssue(key);
      } else {
        this.addDeliveryIssue(key);
      }
    }

    if (Object.keys(this.deliveryIssues).length) {
      $('#sf-my-sub-add-delivery-override-submit')
        .removeClass('primary')
        .prop('disabled', true);
    } else {
      $('#sf-my-sub-add-delivery-override-submit')
        .addClass('primary')
        .prop('disabled', false);
    }
  },
};
