export const accordion = {
  isInitialized: false,

  init: function () {
    if (!this.isInitialized) {
      this.bootstrap();
    }
  },

  bootstrap: function () {
    var acc = document.getElementsByClassName('accordion__heading');
    var i;

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function () {
        this.classList.toggle('active');
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + 'px';
        }
      });
    }
  },
};
