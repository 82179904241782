import { helpers } from '../helpers';

export default class RegioAddressSearchBox {
  constructor() {
    this.addressResultItem = null;
    this.addressResultItems = [];

    this.api_url =
      'https://api.regio.ee/geocode?address={address}&is_complete=true&status={status}&type={type}{include_pois}&address_format={address_format}&limit=10&details=id,address,postcode,type,status,components&apikey={api_key}';
    this.api_key =
      'fOXF1BObA4BXr2Q6X7nQB3LArwwR5ixUvOvHM0v9f_uhSsx2FmaaNe3UAzlHJMVS';
    this.address_format = 'short_address';
    this.api_tid = null;
    this.api_abort = null;
    this.spinner_counter = 0;

    this.input = document.querySelector('.regio-address-search-input');
    this.autocomplete = document.querySelector(
      '.regio-address-search-autocomplete'
    );
    this.searchIcon = document.querySelector('.regio-address-search-icon');
    this.clearButton = document.querySelector('.regio-address-search-clear');
    this.spinner = document.querySelector('.regio-address-search-spinner');
    this.status = '*';
    this.type = '';
    this.includePois = false;
    this.clearAll();

    helpers.log(this);

    this.initializeEvents();
  }

  initializeEvents() {
    this.input.addEventListener('click', this.showAutocomplete.bind(this));
    this.input.addEventListener('blur', () =>
      setTimeout(() => this.hideAutocomplete(), 200)
    );
    this.input.addEventListener('keydown', this.onInputKeydown.bind(this));
    this.input.addEventListener('keyup', this.onInputKeyup.bind(this));
    this.autocomplete.addEventListener(
      'mouseover',
      this.onAutocompleteMouseover.bind(this)
    );
    this.autocomplete.addEventListener(
      'click',
      this.onAutocompleteClick.bind(this)
    );
    this.clearButton.addEventListener('click', () => {
      this.clearAll();
      this.input.focus();
    });
  }

  async performQuery(value) {
    this.abortQuery();
    value = value || '';
    if (!value.trim().length) {
      this.clearAutocomplete();
      this.hideClearButton();
      this.showSearchIcon();
      return;
    }
    this.hideSearchIcon();
    this.hideClearButton();
    this.startSpinner();

    this.api_xhr = new Promise((resolve, reject) => {
      this.api_abort = reject;
      this.api_tid = setTimeout(async () => {
        let url = this.api_url;
        url = url
          .replace('{address}', value)
          .replace('{status}', this.status)
          .replace('{type}', this.type)
          .replace('{address_format}', this.address_format)
          .replace(
            '{include_pois}',
            this.includePois ? '&include_pois=true' : ''
          )
          .replace('{api_key}', this.api_key);
        const req = await fetch(url);
        const res = await req.json();
        this.api_abort = null;
        this.api_tid = null;
        resolve(res);
      }, 250);
    });

    this.api_xhr
      .then((response) => {
        this.renderAutocompleteItems(response, value);
        this.showAutocomplete();
        this.stopSpinner();
        this.showClearButton();
        this.hideSearchIcon();
      })
      .catch(() => {});
  }

  abortQuery() {
    if (this.api_tid) {
      this.api_abort();
      clearTimeout(this.api_tid);
      this.stopSpinner();
      this.resetSpinner();
      this.api_tid = null;
    }
  }

  onInputKeydown(event) {
    switch (event.keyCode) {
      case 27:
        // escape
        if (!this.autocompleteIsHidden()) {
          this.hideAutocomplete();
          event.preventDefault();
          return false;
        }
        break;
      case 38:
        // arrow up
        if (this.autocompleteIsHidden()) {
          this.showAutocomplete();
        } else {
          this.autocompleteHighlightPrev();
        }
        break;
      case 40:
        // arrow down
        if (this.autocompleteIsHidden()) {
          this.showAutocomplete();
        } else {
          this.autocompleteHighlightNext();
        }
        break;
    }
  }

  onInputKeyup(event) {
    switch (event.keyCode) {
      case 13: {
        if (this.autocompleteIsHidden()) {
          this.showAutocomplete();
          return;
        }
        this.selectHighlightedAutocompleteItem();
        this.hideAutocomplete();
        event.preventDefault();
        return false;
      }
      case 27: // escape
      case 37: // arrow left
      case 38: // arrow up
      case 39: // arrow right
      case 40: // arrow down
        break;
      default:
        this.performQuery(event.target.value);
        break;
    }
  }

  clearInput() {
    this.input.value = '';
  }

  onAutocompleteMouseover(event) {
    let target = event.target;
    while (
      target.tagName.toLowerCase() !== 'li' &&
      target !== this.autocomplete
    ) {
      target = target.parentNode;
    }
    this.autocomplete.childNodes.forEach((node) => {
      if (node === target) {
        node.classList.add('regio-address-search-autocomplete-highlight');
      } else {
        node.classList.remove('regio-address-search-autocomplete-highlight');
      }
    });
  }

  onAutocompleteClick(event) {
    let target = event.target;
    while (
      target.tagName.toLowerCase() !== 'li' &&
      target !== this.autocomplete
    ) {
      target = target.parentNode;
    }
    this.autocomplete.childNodes.forEach((node) => {
      if (node === target) {
        node.classList.add('regio-address-search-autocomplete-highlight');
      } else {
        node.classList.remove('regio-address-search-autocomplete-highlight');
      }
    });
    this.selectHighlightedAutocompleteItem();
  }

  renderAutocompleteItems(response, searchValue) {
    this.addressResultItems = response.data || [];
    searchValue = searchValue
      .trim()
      .split(' ')
      .map((value) => value.trim())
      .filter((value) => value.length);
    this.autocomplete.innerHTML = this.addressResultItems
      .map((item) => this.renderAutocompleteItem(item, searchValue))
      .join('');
  }

  renderAutocompleteItem(item, searchValue) {
    let addressText = item.address;
    searchValue = searchValue.map((searchValue) =>
      searchValue.replace(/[`´]+/g, '')
    );

    searchValue.forEach(
      (value) =>
        (addressText = addressText.replace(
          new RegExp('(' + value + ')', 'ig'),
          '```$1´´´'
        ))
    );

    addressText = addressText
      .replace(
        /```/g,
        '<span class="regio-address-search-autocomplete-item-match">'
      )
      .replace(/´´´/g, '</span>');

    return `<li>${addressText}</li>`;
  }

  autocompleteHighlightPrev() {
    let highlighted = null;
    let prev = null;
    let last = null;
    this.autocomplete.childNodes.forEach((node) => {
      if (
        highlighted === null &&
        node.classList.contains('regio-address-search-autocomplete-highlight')
      ) {
        highlighted = node;
      }
      if (highlighted === null) {
        prev = node;
      }
      last = node;
    });
    if (prev === null) {
      prev = highlighted || last;
    }
    if (prev !== null) {
      if (highlighted !== null && highlighted !== prev) {
        highlighted.classList.remove(
          'regio-address-search-autocomplete-highlight'
        );
      }
      if (highlighted !== prev) {
        prev.classList.add('regio-address-search-autocomplete-highlight');
      }
    }
  }

  autocompleteHighlightNext() {
    let highlighted = null;
    let next = null;
    let first = null;
    this.autocomplete.childNodes.forEach((node) => {
      if (first === null) {
        first = node;
      }
      if (highlighted !== null && next === null) {
        next = node;
      }
      if (
        highlighted === null &&
        node.classList.contains('regio-address-search-autocomplete-highlight')
      ) {
        highlighted = node;
      }
    });
    if (next === null) {
      next = highlighted || first;
    }
    if (next !== null) {
      if (highlighted !== null && highlighted !== next) {
        highlighted.classList.remove(
          'regio-address-search-autocomplete-highlight'
        );
      }
      if (highlighted !== next) {
        next.classList.add('regio-address-search-autocomplete-highlight');
      }
    }
  }

  selectHighlightedAutocompleteItem() {
    let index = null,
      idx = 0;
    this.autocomplete.childNodes.forEach((node) => {
      if (
        index === null &&
        node.classList.contains('regio-address-search-autocomplete-highlight')
      ) {
        index = idx;
      }
      idx += 1;
    });
    this.addressResultItem = this.addressResultItems[index] || null;
    if (!this.addressResultItem && this.addressResultItems[0]) {
      this.autocomplete.childNodes[0].classList.add(
        'regio-address-search-autocomplete-highlight'
      );
      this.addressResultItem = this.addressResultItems[0];
    }
    this.dispathEvent();
    return this.addressResultItem;
  }

  dispathEvent() {
    const event = new CustomEvent('addressresultitemchange', {
      detail: this.addressResultItem,
    });
    this.input.dispatchEvent(event);
  }

  autocompleteIsHidden() {
    return this.autocomplete.classList.contains(
      'regio-address-search-autocomplete-hidden'
    );
  }

  showAutocomplete() {
    this.autocomplete.classList.remove(
      'regio-address-search-autocomplete-hidden'
    );
  }

  hideAutocomplete() {
    this.autocomplete.classList.add('regio-address-search-autocomplete-hidden');
  }

  clearAutocomplete() {
    this.autocomplete.innerHTML = '';
  }

  startSpinner() {
    this.spinner_counter += 1;
    if (this.spinner_counter > 0) {
      this.showSpinner();
    }
  }

  stopSpinner() {
    this.spinner_counter -= 1;
    if (this.spinner_counter < 1) {
      this.spinner_counter = 0;
    }
    if (this.spinner_counter === 0) {
      this.hideSpinner();
    }
  }

  resetSpinner() {
    this.spinner_counter = 0;
  }

  showSpinner() {
    this.spinner.classList.remove('regio-address-search-spinner-hidden');
  }

  hideSpinner() {
    this.spinner.classList.add('regio-address-search-spinner-hidden');
  }

  showClearButton() {
    this.clearButton.classList.remove('regio-address-search-clear-hidden');
  }

  hideClearButton() {
    this.clearButton.classList.add('regio-address-search-clear-hidden');
  }

  showSearchIcon() {
    this.searchIcon.classList.remove('regio-address-search-icon-hidden');
  }

  hideSearchIcon() {
    this.searchIcon.classList.add('regio-address-search-icon-hidden');
  }

  clearAll() {
    this.abortQuery();
    this.clearInput();
    this.clearAutocomplete();
    this.hideAutocomplete();
    this.hideSpinner();
    this.hideClearButton();
    this.showSearchIcon();
    this.addressResultItem = null;
    this.addressResultItems = [];
    this.dispathEvent();
  }
}
